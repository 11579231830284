<template>
  <div class="py-10 lg:py-20 px-4 lg:px-0">
    <div class="max-w-wrapper-sm mx-auto">
      <atoms-card
        size="standard"
        class="text-center p-6 pb-8 lg:p-10 lg:pb-12"
        shadow="none"
      >
        <p
          v-if="$slots.header"
          class="md:text-[2rem] text-2xl md:text-center text-left md:leading-10"
        >
          <slot name="header"></slot>
        </p>

        <p
          v-if="$slots['sub-header']"
          class="text-base md:text-lg text-left md:text-center mt-4 mb-6"
        >
          <slot name="sub-header"></slot>
        </p>

        <atoms-button
          :has-icon="btnHasIcon"
          :full="devices.isMobile.value"
          :to="!btnLabel ? `tel:${ctaPhoneNumber}` : btnUrl"
          :enable-tracking="btnUrl !== ''"
          :class="{
            'mb-4': showSchedule
          }"
          theme="primary"
          size="large"
          class="m-auto"
        >
          <template v-if="!btnLabel">
            CALL&nbsp;<span class="hidden md:inline-block">US ON</span>&nbsp;{{ ctaPhoneNumber }}
          </template>

          <!-- eslint-disable vue/no-v-html -->
          <span
            v-else
            v-html="btnLabel"
          >
          </span>
        </atoms-button>

        <template v-if="showSchedule">
          <p class="font-bold text-lg md:text-center text-left">
            {{ ctaSchedule }}
          </p>

          <p
            v-if="ctaScheduleWeekend"
            class="font-bold text-lg md:text-center text-left"
          >
            {{ ctaScheduleWeekend }}
          </p>
        </template>

        <div
          v-if="$slots['additional-info']"
        >
          <slot name="additional-info"></slot>
        </div>
      </atoms-card>
    </div>
  </div>
</template>

<script setup>
const { devices } = deviceChecker()

defineOptions({
  name: 'MoleculesHomeCta'
})

defineProps({
  ctaPhoneNumber: {
    type: String,
    required: true
  },

  ctaSchedule: {
    type: String,
    required: true
  },

  ctaScheduleWeekend: {
    type: String,
    default: null
  },

  btnHasIcon: {
    type: Boolean,
    default: true
  },

  btnLabel: {
    type: String,
    default: ''
  },

  btnUrl: {
    type: String,
    default: ''
  },

  showSchedule: {
    type: Boolean,
    default: true
  }
})
</script>
